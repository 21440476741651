<template>
  <div>
    <h1 class="text-4xl text-center py-10">My Courses</h1>

    <div class="relative w-full max-w-6xl mx-auto my-3">
      <div class="w-full my-3">
        <div class="my-3 relative" style="min-height: 400px">

          <overly class="rounded" background="bg-blue-100" v-if="loading">
            <spinner class="text-red-600 h-10 w-10"></spinner>
            Loading...
          </overly>

          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 w-full">
            <student-course-item v-for="participant in courses" :participant="participant" :key="participant.id"></student-course-item>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StudentCourseItem from "./components/student-course-item.vue";
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
export default {
  name: "index",
  components: {Spinner, Overly, StudentCourseItem},
  data(){

    return {
      courses: [],
      loading: true
    }

  },
  created() {

    this.$store.dispatch( 'batch/getStudentCourses', {

      params: {
        branch_courses_only: 'true',
        with: 'batch-and-course,service-point-and-branch'
      },

      success: ({data}) => {
        this.courses = data.courses;
      },

      error:(response) => { }

    } ).finally( () => this.loading = false )
  }

};
</script>

<style scoped>
</style>
